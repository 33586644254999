import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import ReactHtmlParser from 'react-html-parser'

class Lightboxes extends Component {
  constructor (props) {
    super(props)

    this.state = {
      galleryOpenStates: {},
      galleryIndices: {},
      gallerySizes: {}
    }

    for (const gal of props.galleries) {
      this.state.galleryOpenStates[gal.galleryId] = false
      this.state.galleryIndices[gal.galleryId] = 0
      this.state.gallerySizes[gal.galleryId] = gal.images.length
    }
  }

  setLightboxState (galleryId, active) {
    const newState = {
      galleryOpenStates: { ...this.state.galleryOpenStates }
    }

    newState.galleryOpenStates[galleryId] = active

    this.setState(newState)
  }

  _getActiveGallery () {
    let active
    for (const id in this.state.galleryOpenStates) {
      if (!this.state.galleryOpenStates.hasOwnProperty(id)) continue
      if (!this.state.galleryOpenStates[id]) continue
      active = id
      break
    }

    return this.props.galleries.find(gal => gal.galleryId === active)
  }

  _shiftImage (galleryId, difference) {
    const newState = {
      galleryIndices: { ...this.state.galleryIndices }
    }

    let index = (newState.galleryIndices[galleryId] + difference) % this.state.gallerySizes[galleryId]
    if (index < 0) index = (newState.galleryIndices[galleryId] + difference + this.state.gallerySizes[galleryId]) % this.state.gallerySizes[galleryId]

    newState.galleryIndices[galleryId] = index

    this.setState(newState)
  }

  _getCurrentImage (current) {
    return require(`../../../datasources/gallery/${current.images[this.state.galleryIndices[current.galleryId]].path}`)
  }

  _getNextImage (current) {
    return require(`../../../datasources/gallery/${current.images[(this.state.galleryIndices[current.galleryId] + 1) % current.images.length].path}`)
  }

  _getPrevImage (current) {
    return require(`../../../datasources/gallery/${current.images[(this.state.galleryIndices[current.galleryId] - 1 + current.images.length) % current.images.length].path}`)
  }

  _getDescription (current) {
    let description = current.images[this.state.galleryIndices[current.galleryId]].description

    const image = current.images[this.state.galleryIndices[current.galleryId]]
    if (image.copyrightNotice) {
      return `${description}<br /><br />${image.copyrightNotice}`
    }

    if (current.copyrightNotice) {
      return `${description}<br /><br />${current.copyrightNotice}`
    }

    return description
  }

  render () {
    const current = this._getActiveGallery()

    if (!current) return <span />
    return (<div>
      <Lightbox
        mainSrc={this._getCurrentImage(current)}
        nextSrc={this._getNextImage(current)}
        prevSrc={this._getPrevImage(current)}
        imageTitle={current.title}
        imageCaption={ReactHtmlParser(this._getDescription(current))}
        onCloseRequest={() => this.setLightboxState(current.galleryId, false)}
        onMoveNextRequest={() => this._shiftImage(current.galleryId, 1)}
        onMovePrevRequest={() => this._shiftImage(current.galleryId, -1)}
        style={{ overlay: { fontSize: '1.8vh' } }}
      />
    </div>)
  }
}

Lightboxes.propTypes = {
  galleries: PropTypes.array.isRequired
}

export default Lightboxes
