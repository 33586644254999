import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from './logo.png'

class Logo extends Component {
  constructor (props) {
    super(props)

    this.divStyle = {
      overflow: 'hidden',
      textAlign: 'left'
    }

    this.imgStyle = {
      width: '33.7vh',
      textAlign: 'left'
    }
  }

  render () {
    return (
      <Link id="logo" style={this.divStyle} to='/'>
        <img
          src={logo}
          style={this.imgStyle}
          alt="Verena Ebel Restaurierung von Gemälden, Skulpturen, und Objekten der Moderne"
        />
      </Link>
    )
  }
}

export default Logo
