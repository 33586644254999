import React, { Component } from 'react'

class Contact extends Component {
  constructor (props) {
    super(props)

    this.divEmailStyle = this.divTelephoneStyle = {
      display: 'block'
    }
  }

  render () {
    return (<div>
      <span style={this.divEmailStyle}>mail@verena-ebel.de</span>
      <span style={this.divTelephoneStyle}>0176 64 09 63 44</span>
    </div>)
  }
}

export default Contact
