import AbstractContent from './../AbstractContent'

/**
 * @abstract
 */
class ClickablePreview extends AbstractContent {
  constructor (props) {
    super(props)

    this.state = { active: false }
  }

  unselect () {
    this.setState({ active: false })
  }

  _onClickOutside (additionalClickHandler = null) {
    this.unselect()

    if (additionalClickHandler) {
      additionalClickHandler()
    }
  }

  _onClick (additionalClickHandler = null) {
    if (this.state.active) {
      return
    }

    this.setState({ active: true })

    if (additionalClickHandler) { additionalClickHandler() }
  }
}

export default ClickablePreview
