export default [
  {
    pathId: '3380bc96-48a2-11e9-be67-7bf535c789e4',
    path: '/'
  },
  {
    pathId: '0658cce6-49cd-11e9-9116-d35470e0d803',
    path: '/leistungen'
  },
  {
    pathId: '9bcea0f0-54ba-11e9-8ea8-e38668200a3b',
    path: '/galerie'
  },
  {
    pathId: '2409e2fa-6441-11e9-8d3a-37bcc4b15d1b',
    path: '/profil'
  },
  {
    pathId: '0b25e96c-6443-11e9-96b4-e75a9260bd4c',
    path: '/kontakt'
  },
  {
    pathId: 'b0b693f4-6443-11e9-87c0-d36e31161ceb',
    path: '/anfrage'
  },
  {
    pathId: '6fe52d3a-82da-11e9-9e81-6b9eb961e3d3',
    path: '/referenzen'
  },
  {
    pathId: '3f20f91c-48a2-11e9-8e78-3b7da36e777b',
    path: '/impressum'
  },
  {
    pathId: '7c69af4e-65ee-11e9-b474-efe4ab2a5b4a',
    path: '/datenschutz'
  },
  {
    pathId: '4979dea6-48a2-11e9-9368-431be68af38f',
    path: '/article/:articleId'
  },
  {
    pathId: '8054a614-48b0-11e9-a38e-3fe930dce87f',
    path: '*'
  }
]
