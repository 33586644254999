import React, { Component } from 'react'

class AdditionalInformation extends Component {
  constructor (props) {
    super(props)

    this.divStyle = {
      paddingTop: '3.53vh',
      width: '37vh',
      height: '30vh',
      float: 'right'
    }

    this.innerComponent = null
  }

  getAdditionalInformationComponent () {
    return this.innerComponent
  }

  render () {
    return (
      <div id="additionalInformation" style={this.divStyle}>
        <this.props.additionalInformationComponent ref={ref => (this.innerComponent = ref)}/>
      </div>
    )
  }
}

export default AdditionalInformation
