export default [
  {
    galleryId: '3c218f2a-58a5-11e9-be93-9f4f731c851d',
    title: 'Fürstenberg-Stiftung „Kleine Freuden“',
    description: '<div style="margin-bottom: .7vh">Konservierung und Restaurierung ausgewählter Gemälde der Fürstenberg-Stiftung Eggeringhausen mit ' +
      'Unterstützung des “Restaurierungsprogramms Bildende Kunst des Landes NRW“. 2017</div>\n' +
      '<div>Zu sehen sind die Gemälde seit dem 28. September 2018 in der Ausstellung „Kleine Freuden“ im Residenzmuseum ' +
      'Schloss Neuhaus. Ein Kurzbeitrag zu den Maßnahmen ist im gleichnamigen ' +
      'Begleitheft zur Ausstellung erschienen.</div><br />\n' +
      '<div>Bestand: FÜRSTENBERG-STIFTUNG Eggerinhausen</div>',
    thumbnail: '1-Fuerstenberg-klein.jpg',
    copyrightNotice: 'Bestand: FÜRSTENBERG-STIFTUNG Eggerinhausen',
    images: [
      {
        path: 'fuerstenberg/1.1 Vinckboons Vorzustand_klein.jpg',
        description: 'Vorzustand. Zwei dem niederländischen Maler David Vinckboons zugeschriebene, um 1610 entstandene Landschaftsminiaturen auf Kupfer.',
        sort: 1
      },
      {
        path: 'fuerstenberg/1.2 Vinckboons waehrend FA klein.jpg',
        description: 'Während der Firnisabnahme ist die Verbräunung des Firnis deutlich zu erkennen.',
        sort: 2
      },
      {
        path: 'fuerstenberg/1.3 Vinckboons Detail.jpg',
        description: 'Mikroskopaufnahme des Blattwerks vor (links) und nach der Restaurierung (rechts). Der verbräunte Firnis hatte sich vor allem in den Malschichttiefen angesammelt.',
        sort: 3
      },
      {
        path: 'fuerstenberg/1.4 Vinckboons Kittung klein.jpg',
        description: 'Kleinere Malschichtfehlstellen wurden nach der Firnisabnahme gekittet.',
        sort: 4
      },
      {
        path: 'fuerstenberg/1.5 Vinckboons nach_klein.jpg',
        description: 'Die Gemälde nach der Restaurierung. Die Kittungen wurden retuschiert und ein neuer Firnis aufgetragen. ' +
          'Die Rahmenfalzen wurden ausgepolstert und die Tafeln in einem Rückseitenschutz eingebettet eingerahmt.',
        sort: 5
      },
      {
        path: 'fuerstenberg/2.1 Heeremans Vorzustand klein.jpg',
        description: 'Vorzustand. Eine auf Leinwand gemalte Winterlandschaft des holländischen Malers Thomas Heeremans von 1684.',
        sort: 6
      },
      {
        path: 'fuerstenberg/2.2 Heeremans waehrend FA.jpg',
        description: 'Auf Grund von störenden Krepierungen und starker Vergilbung wurde eine Firnisabnahme durchgeführt.',
        sort: 7
      },
      {
        path: 'fuerstenberg/2.3 Heeremans Detail Himmel.jpg',
        description: 'Vor allem im Bereich des Himmels deckten Übermalungen weite Teile der originalen Malerei ab (links). ' +
          'Sie waren auf Grund lokaler Fehlstellen aufgetragen worden. Nach Abnahme der Übermalungen (Mitte) wurden nur die ' +
          'Fehlstellen retuschiert (rechts).',
        sort: 8
      },
      {
        path: 'fuerstenberg/2.4 Heeremans Detail Schlitten_klein.jpg',
        description: 'Detail vor (links) und nach der Firnisabnahme (Mitte) sowie nach der Restaurierung (rechts). Verputzungen ' +
          'im Bereich des Schlittens wurden dezent retuschiert.',
        sort: 9
      },
      {
        path: 'fuerstenberg/2.5 Heeremans nach.jpg',
        description: 'Das Gemälde nach der Restaurierung.',
        sort: 10
      },
      {
        path: 'fuerstenberg/3.1 Bellevois Vorzustand.jpg',
        description: 'Vorzustand. Ein auf Eichenholz gemaltes Seestück mit der Stadtansicht von Dordrecht. Auf den Maler ' +
          'Jacob Adriaensz Bellevois (1621-1676) weist ein Monogramm hin.',
        sort: 11
      },
      {
        path: 'fuerstenberg/3.2 Bellevois Detail OfR klein.jpg',
        description: 'Durch eine erste Oberflächenreinigung konnte aufliegender Schmutz entfernt werden.',
        sort: 12
      },
      {
        path: 'fuerstenberg/3.3 Bellevois waehrend FA klein.jpg',
        description: 'Es folgte die Abnahme des vergilbten Firnisses.',
        sort: 13
      },
      {
        path: 'fuerstenberg/3.4 Bellevois Details Wolke_klein.jpg',
        description: 'Im Bereich der Wolke war die Malerei bereits durch frühere Maßnahmen stark reduziert (links). ' +
          'Nach Kittung kleinerer Malschichtfehlstellen wurden die verputzten Bereiche dezent retuschiert.',
        sort: 14
      },
      {
        path: 'fuerstenberg/3.5 Bellevois nach.jpg',
        description: 'Das Gemälde nach der Restaurierung.',
        sort: 15
      },
      {
        path: 'fuerstenberg/4.1 Palamedsz Vorzustand klein.jpg',
        description: 'Vorzustand. Holztafelgemälde einer Wachstubenszene mit kartenspielenden Soldaten um 1650/60.',
        sort: 16
      },
      {
        path: 'fuerstenberg/4.2 Palamedesz Details Fahne_klein.jpg',
        description: 'Im Bereich der Fahne wurde der Firnis in einer früheren Maßnahme bereits abgenommen. In den ' +
          'umliegenden Bereichen war er jedoch stark krepiert und verbräunt (links). Nach einer vollständigen ' +
          'Firnisabnahme (Mitte) kommt die detaillierte Malerei nach der Restaurierung wieder gut zur Geltung (rechts).',
        sort: 17
      },
      {
        path: 'fuerstenberg/4.3 Palamedesz Detail Spieler_klein.jpg',
        description: 'Auch im Bereich der zentralen Personengruppe war ehemals eine Teilfirnisabnahme durchgeführt worden. ' +
          'Zu starke Lösemittel verputzten dabei Teile der Malerei (links). Nach der Firnisabnahme (Mitte) wurden die ' +
          'fehlenden Details zurückhaltend retuschiert und ein neuer Firnis aufgetragen (rechts).',
        sort: 18
      },
      {
        path: 'fuerstenberg/4.4 Palamedesz nach klein.jpg',
        description: 'Das Gemälde nach der Restaurierung. Die Tafel wurde im Rahmen vermittelt und die Rahmenfalz gepolstert.',
        sort: 19
      },
      {
        path: 'fuerstenberg/5.1 Eisvogel Vorzustand.jpg',
        description: 'Vorzustand. Über die Beschriftung des Papiertütchens konnte das Stillleben mit Eisvogel, Kirschen ' +
          'und einer Maus dem Maler Martinus N. Nellius (1621-1719) zugeschrieben werden. Die Malschicht des Holztafelgemäldes ' +
          'zeigte bereits starke Bearbeitungsspuren.',
        sort: 20
      },
      {
        path: 'fuerstenberg/5.2 Eisvogel OfR klein.jpg',
        description: 'In einer Untersuchung wurde der Zustand der stark überarbeiteten Malschicht dokumentiert und bewertet. ' +
          'Zur Schonung der Malerei wurde von einer weiteren Firnisabnahme abgesehen und das Gemälde ausschließlich konserviert. ' +
          'Auf dem Bild zeigt die linke, bereits gereinigte Bildhälfte wieder mehr Tiefenwirkung.',
        sort: 21
      },
      {
        path: 'fuerstenberg/5.3 Eisvogel Rahmen klein.jpg',
        description: 'Großflächige Malschichtabhebungen am Rahmen wurden gefestigt und Ausbruchkanten retuschiert.',
        sort: 22
      },
      {
        path: 'fuerstenberg/5.4 Eisvogel nach.jpg',
        description: 'Zustand nach der Konservierung.',
        sort: 23
      },
      {
        path: 'fuerstenberg/6.1 Blumenstillleben nach_klein.jpg',
        description: 'Auf Eichenholz gemaltes Blumenstillleben um 1650 nach der Konservierung. Ähnlich wie bei dem Eisvogel ' +
          'wurde auf Grund zahlreicher Überarbeitungsspuren und des besonderen Objektwertes zunächst der Bestand untersucht und dokumentiert.',
        sort: 24
      },
      {
        path: 'fuerstenberg/6.2 Blumenstillleben UV Firnis klein.jpg',
        description: 'Unter UV-Strahlung zeigt sich, dass der aufliegende Firnis in einer Fehlstelle liegt. Er wurde ' +
          'aufgetragen als bereits ein kleiner Schaden vorhanden war, was darauf hindeutet, dass es sich nicht mehr ' +
          'um den ursprünglichen Firnis des Gemäldes handelt.',
        sort: 25
      },
      {
        path: 'fuerstenberg/6.3 Blumenstillleben Maltechnik klein.jpg',
        description: 'Unter dem Mikroskop kann der Schichtaufbau und damit die Reihenfolge der Malweise an Ausbruchkanten rekonstruiert werden.',
        sort: 26
      },
      {
        path: 'fuerstenberg/6.4 Blumenstillleben OfR klein.jpg',
        description: 'Durch die Abnahme des aufliegenden Oberflächenschmutzes (rechte Bildhälfte) konnte die Tiefenwirkung der Malerei gesteigert werden.',
        sort: 27
      }
    ]
  },
  {
    galleryId: '88e038a6-58a6-11e9-9e4e-a351f95d4a62',
    title: 'Erper Kruzifix',
    description: 'Konservierung des sogenannten „Erper Kruzifix“ aus dem 12. Jahrhundert an Kolumba, ' +
      'Kunstmuseum des Erzbistums Köln. 2017<br /><br />Bestand: Kolumba Köln<br />Foto: Lothar Schnepf',
    thumbnail: '2-Kruzifixus-Erp-klein.jpg',
    images: [
      {
        path: 'erper_kruzifix/0 Erper Vorzustand.jpg',
        description: 'Vorzustand des „Erper Kruzifix“. Der Korpus misst eine Höhe von etwa 146 cm. Bei der ' +
          'Sichtfassung handelt es sich um eine Rekonstruktion nach Resten des 15. Jahrhunderts.',
        sort: 1
      },
      {
        path: 'erper_kruzifix/1.1 Erper Details OfR_klein.jpg',
        description: 'Staub- und Schmutzauflagen erzeugten eine matte, stumpfe Oberfläche (links). Nach deren Abnahme ' +
          'konnte durch die Reaktivierung eines krepierten Wachsüberzuges die Brillanz der Fassung etwas erhöht werden (rechts).',
        sort: 2
      },
      {
        path: 'erper_kruzifix/1.2 Erper Detail Reduz. Festigungsmittel_klein.jpg',
        description: 'Unter UV-Strahlung deutlich sichtbare Klebemittelreste einer älteren Maßnahme zur Fassungsfestigung ' +
          '(links) wurden reduziert (rechts).',
        sort: 3
      },
      {
        path: 'erper_kruzifix/1.3 Erper Detail Bart vor nach klein.jpg',
        description: 'Im Rahmen der Konservierung wurde neben der Oberflächenreinigung eine Holz- und Fassungsfestigung ' +
          'durchgeführt. Helle Ausbruchkanten wurden dezent retuschiert.',
        sort: 4
      }
    ]
  },
  {
    galleryId: '8f76f2cc-58a6-11e9-8840-ab4295fc5bd9',
    title: 'Nachtlandschaft',
    description: 'Konservierung und Restaurierung einer kleinen auf Eichenholz gemalten Nachtlandschaft aus Privatbesitz. 2017',
    thumbnail: '3-Nachtlandschaft-klein.jpg',
    images: [
      {
        path: 'nachtlandschaft/1.1 Nachtlandschaft OfR klein.jpg',
        description: 'Während der Oberflächenreinigung wurde lose aufliegender Staub entfernt. Das rechte Bilddrittel ist bereits gereinigt.',
        sort: 1
      },
      {
        path: 'nachtlandschaft/1.2 Nachtlandschaft waehrend FA klein.jpg',
        description: 'Der stark vergilbte Firnis wurde nach kleineren Beprobungen abgenommen.',
        sort: 2
      },
      {
        path: 'nachtlandschaft/1.3 Nachtlandschaft nach klein.jpg',
        description: 'Nach der Firnisabnahme wurde eine kleine Fehlstelle im Himmel gekittet und retuschiert. Es erfolgte ein neuer Firnisauftrag.',
        sort: 3
      },
      {
        path: 'nachtlandschaft/1.4 Nachtlandschaft nach Rahmen_klein.jpg',
        description: 'Das Gemälde nach der Restaurierung. Die Rahmenfalz wurde ausgepolstert und die Einrahmung nach aktuellen Standards erneuert.',
        sort: 4
      }
    ]
  },
  {
    galleryId: '92dc22ac-58a6-11e9-b12b-070724f5e992',
    title: 'Hl. Barbara',
    description: 'Konservierung und Restaurierung einer um 1360/80 vermutlich als Madonna entstandenen und im ' +
      'Mittelalter umgearbeiteten Hl. Barbara der St. Barbara-Bruderschaft Straelen. 2018',
    thumbnail: '4-Hl-Barbara-klein.jpg',
    images: [
      {
        path: 'hl_barbara/1.1 Barbara Vorzustand.jpg',
        description: 'Vorzustand. Die etwa 1360/80 entstandene Hl. Barbara stand in einem außenbewitterten Giebel. ' +
          'Die Skulptur war stark verwittert und vielfach überfasst.',
        copyrightNotice: 'Foto: LVR-Amt für Denkmalpflege im Rheinland',
        sort: 10
      },
      {
        path: 'hl_barbara/1.2 Barbara Fassungsuntersung.jpg',
        description: 'Mikroskopaufnahme. Gibt es kleinere Ausbrüche in der Fassung, kann mit dem Mikroskop z.B. die ' +
          'Schichtabfolge der Farbfassungen am Objekt zerstörungsfrei untersucht werden.',
        sort: 20
      },
      {
        path: 'hl_barbara/1.2.2 Barbara Querschliff.jpg',
        description: 'Eine eingebettete und angeschliffene Fassungsscholle - ein sogenannter Querschliff - unter dem ' +
          'Mikroskop. Das Konzept des LVR-Amts für Denkmalpflege sah eine Freilegung auf eine Holzsichtigkeit vor. ' +
          'Vorhandene Farbfassungen wurden vor der Abnahme dokumentiert.',
        sort: 25
      },
      {
        path: 'hl_barbara/1.3 Barbara Gesicht.jpg',
        description: 'Das Gesicht der Hl. Barbara im Vorzustand, während der Fassungsabnahme, während der Kittung und nach der Restaurierung (v.l.)',
        copyrightNotice: 'Vorzustandsfoto: LVR-Amt für Denkmalpflege im Rheinland',
        sort: 30
      },
      {
        path: 'hl_barbara/1.4 Barbara Details Faltenwurf.jpg',
        description: 'Ein Detail des Faltenwurfs während der Freilegung (oben), der Kittung (u. links) und nach der ' +
          'Restaurierung (u. rechts). Durch Insektenfraß zerstörte, rekonstruierbare Stellen wurden gekittet und damit ' +
          'die Ablesbarkeit der Konturen wiederhergestellt.',
        sort: 40
      },
      {
        path: 'hl_barbara/1.5 Barbara nach.JPG',
        description: 'Nach der Restaurierung zeigt sich die Nähe der Hl. Barbara zu den sogenannten „Schönen Madonnen“.',
        sort: 50
      }
    ]
  },
  {
    galleryId: '966d6c6e-58a6-11e9-97f3-a78b0e137e5a',
    title: 'Halterungen St. Servatius',
    description: 'Anfertigung individueller Halterungen für Fragmente des Annoschreins in Zusammenarbeit mit der ' +
      'Domgoldschmiede Köln für die Schatzkammer St. Servatius in Siegburg. 2016',
    thumbnail: '5-Halterung-Servatius-klein.jpg',
    images: [
      {
        path: 'halterung_servatius/0 Servatius Vitrine Halterung klein.jpg',
        description: 'Für die filigranen Fragmente des Annoschreins wurde in Zusammenarbeit mit der Domgoldschmiede ' +
          'Köln eine passgenaue Halterung aus Messing angefertigt.',
        sort: 1
      },
      {
        path: 'halterung_servatius/1.1 Servatius Montage ohne Objekte klein.jpg',
        description: 'Die Halterungen vor Einbringen der Objekte.',
        sort: 2
      },
      {
        path: 'halterung_servatius/1.2 Servatius Helm klein.jpg',
        description: 'Detail der Halterung für ein Helm-Fragment des Annoschreins.',
        sort: 3
      },
      {
        path: 'halterung_servatius/1.3 Servatius Schild klein.jpg',
        description: 'Detail der Halterung für ein Schild-Fragment des Annoschreins.',
        sort: 4
      }
    ]
  },
  {
    galleryId: '9aae0c02-58a6-11e9-a5b5-6728f7e2c9ef',
    title: 'Sichtwartung St. Servatius',
    description: 'Sichtwartung der Schatzkammerobjekte in St. Servatius in Siegburg sowie Einrichtung und Betreuung der ' +
      'Klimatechnik. Seit 2016<br /><br />Foto: Robert Bernhardt',
    thumbnail: '6 Servatius-Schatzkammer-bearb.jpg',
    images: [
      {
        path: 'wartung_servatius/1.1 Wartung Servatius Objekt klein.jpg',
        description: 'Die Ausstellungsstücke werden einer Sichtwartung unterzogen, auffällige Stellen fotografisch ' +
          'dokumentiert und auf Veränderungen hin überprüft.',
        sort: 1
      },
      {
        path: 'wartung_servatius/1.2 Wartung Servatius Klimatechnik klein.jpg',
        description: 'Einige Vitrinen werden aktiv klimatisiert. Die Bedienung und Wartung der Geräte zählt zum Wartungsumfang.',
        sort: 2
      },
      {
        path: 'wartung_servatius/1.3 Wartung Servatius Datenlogger klein.jpg',
        description: 'Gemessene und aufgezeichnete Klimadaten werden ausgewertet und archiviert.',
        sort: 3
      },
      {
        path: 'wartung_servatius/1.4 Wartung Servatius Staub klein.jpg',
        description: 'Über einen längeren Zeitraum andauernde Monitorings können im Rahmen einer Wartung durchgeführt werden.',
        sort: 4
      }
    ]
  }
]
