import React from 'react'
import * as PropTypes from 'prop-types'

import AbstractContent from './../AbstractContent'

class Overlay extends AbstractContent {
  constructor (props) {
    super(props)

    this.overlayStyle = {
      width: '22.4vh',
      cursor: 'pointer',
      paddingTop: '5.8vh',
      position: 'absolute',
      zIndex: 1,
      background: '#222222',
      opacity: 0.7,
      marginTop: '2vh',
      height: '12.5vh',
      color: 'white',
      fontSize: '5vh',
      textAlign: 'center',
      top: '-2vh'
    }
  }

  render () {
    const overlayStyle = {
      ...this.overlayStyle,
      marginLeft: this.props.marginLeft,
      marginRight: this.props.marginRight
    }

    return <div
      style={overlayStyle}
      onClick={() => this.props.onClick()}
    >+<div style={{ fontSize: '1.7vh' }}>zu den Fotos</div></div>
  }
}

Overlay.propTypes = {
  marginLeft: PropTypes.string.isRequired,
  marginRight: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default Overlay
