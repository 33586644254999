import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as PropTypes from 'prop-types'

class Menu extends Component {
  constructor (props) {
    super(props)

    this.entries = [
      { key: 'willkommen', name: 'Willkommen', href: '/' },
      { key: 'leistungen', name: 'Leistungen' },
      { key: 'galerie', name: 'Galerie' },
      { key: 'referenzen', name: 'Referenzen' },
      { key: 'profil', name: 'Profil' },
      // { key: 'anfrage', name: 'Anfrage' },
      { key: 'kontakt', name: 'Kontakt' }
    ]

    this.state = {
      activeEntry: this.getActiveEntry()
    }

    this.boxStyle = {
      display: 'table',
      width: '36.8vh',
      height: '30vh',
      paddingTop: '1.4vh',
      float: 'right',
      color: '#fefefe',
      background: '#be3d27',
      border: '1px solid #be3d27',
      textAlign: 'center'
    }

    this.boxStyleWelcome = {
      ...this.boxStyle,
      background: '#545253',
      border: '1px solid #545253'
    }

    this.innerBoxStyle = {
      display: 'table-cell',
      verticalAlign: 'middle'
    }

    this.entryStyle = {
      lineHeight: '3.6vh',
      fontSize: '2vh'
    }

    this.activeEntryStyle = {
      ...this.entryStyle,
      fontSize: '4vh',
      marginTop: '3.5vh',
      marginBottom: '2.5vh'
    }

    this.linkStyle = {
      color: '#fefefe'
    }
  }

  getActiveEntry () {
    return (
      this.entries.find(entry => entry.key === this.props.location.pathname.slice(1)) ||
      this.entries.find(entry => entry.href === this.props.location.pathname) ||
      { key: '' }
    ).key
  }

  getBoxStyle () {
    return this.state.activeEntry === 'willkommen' ? this.boxStyle : this.boxStyleWelcome
  }

  getEntryStyle (entry) {
    return entry.key === this.state.activeEntry ? this.activeEntryStyle : this.entryStyle
  }

  componentDidUpdate () {
    const activeEntry = this.getActiveEntry()
    if (this.state.activeEntry !== activeEntry) this.setState({ activeEntry })
  }

  render () {
    return (
      <div id="menu" style={this.getBoxStyle()}>
        <div style={this.innerBoxStyle}>
          {this.entries.map(entry => {
            const linkTarget = entry.href || entry.key
            return (
              <Link key={entry.key} to={linkTarget} style={this.linkStyle}>
                <div style={this.getEntryStyle(entry)} key={entry.key}>{entry.name}</div>
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
}

Menu.propTypes = {
  location: PropTypes.object.isRequired
}

export default Menu
