import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Footer extends Component {
  constructor (props) {
    super(props)

    this.state = { currentFont: 'century-gothic' }

    this.divStyle = {
      width: '126.8vh',
      padding: '1.7vh 1.2vh .6vh 1.2vh',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '1.75vh'
    }

    this.leftDivStyle = {
      float: 'left'
    }

    this.rightDivStyle = {
      textAlign: 'right'
    }
  }

  _toggleFont () {
    const font = this.state.currentFont === 'century-gothic' ? 'Questrial' : 'century-gothic'

    document.body.style.fontFamily = `${font}, 'sans-serif'`
    this.setState({ currentFont: font })
  }

  render () {
    return (
      <div id="footer" style={this.divStyle}>
        <div style={this.leftDivStyle}><Link style={{ color: 'black' }} to={'/datenschutz'}>Datenschutz</Link></div>
        <div style={this.rightDivStyle}><Link style={{ color: 'black' }} to={'/impressum'}>Impressum</Link></div>
      </div>
    )
  }
}

export default Footer
