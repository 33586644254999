import React, { Component } from 'react'
import Street from './Street'
import Contact from './Contact'

class Address extends Component {
  constructor (props) {
    super(props)

    this.divStyle = {
      marginTop: '1.5vh',
      textAlign: 'right',
      float: 'right',
      fontSize: '1.75vh'
    }
  }

  render () {
    return (
      <div id="address" style={this.divStyle}>
        <Street />
        <Contact />
      </div>
    )
  }
}

export default Address
