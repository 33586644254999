import React from 'react'
import AbstractContent from '../ContentComponent/AbstractContent'

class Content extends AbstractContent {
  constructor (props) {
    super(props)

    this.divStyle = {
      width: '75.2vh',
      height: '65vh',
      float: 'left',
      border: '1px solid #999999',
      color: '#545253'
    }

    this.contentComponent = null

    this.state = { borderWidth: '2px' }
  }

  getContentComponent () {
    return this.contentComponent
  }

  render () {
    return (
      <div id="displayArea" style={this.divStyle}>
        <this.props.contentComponent {...this.props} ref={ref => (this.contentComponent = ref)}/>
      </div>
    )
  }
}

export default Content
