import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

class Separator extends Component {
  constructor (props) {
    super(props)

    this.separatorStyle = {
      maxHeight: '2px',
      background: '#be3d27',
      clear: 'both',
      marginTop: props.marginTop || '0',
      marginBottom: props.marginBottom || 0
    }

    this.state = {
      width: '100vw'
    }
  }

  componentDidMount () {
    this._setLineDimensions()
    window.addEventListener('resize', () => this._setLineDimensions())
  }

  _setLineDimensions () {
    const html = document.getElementsByTagName('html')[0]

    let height = Math.round((0.18 * window.innerHeight) / 100)
    if (height === 0) height = 1
    this.setState({ width: html.scrollWidth, height })
  }

  render () {
    return (
      <div style={{ ...this.separatorStyle, height: this.state.height, width: `${this.state.width}px` }} />
    )
  }
}

Separator.propTypes = {
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string
}

export default Separator
