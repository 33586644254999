import React, { Component } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ReactHtmlParser from 'react-html-parser'

class GalleryInfoBox extends Component {
  constructor (props) {
    super(props)

    this.tableDivStyle = {
      display: 'block',
      width: '36.8vh',
      height: '30vh',
      border: '1px solid #999999'
    }

    this.borderDivStyle = {
      position: 'relative',
      display: 'block',
      width: '32.8vh',
      height: '26vh',
      border: '2vh solid white',
      overflowY: 'auto'
    }

    this.tableCellDivStyle = {
      display: 'table-cell',
      width: '36.8vh',
      height: '26vh',
      textAlign: 'left',
      verticalAlign: 'bottom',
      color: '#555555',
      fontSize: '1.7vh'
      // background: '#fafafa',
    }

    this.defaultContent =
      '<div style="margin-bottom: .7vh">Klicken Sie auf ein Projekt Ihrer Wahl, um Informationen darüber zu erhalten.</div>' +
      '<div>Mit einem zweiten Klick auf das Projekt gelangen Sie in die Foto-Galerie.</div>'

    this.state = { key: null, information: this.defaultContent }
  }

  setInformation (key, information) {
    this.setState({ key, information })
  }

  clearInformation (key) {
    this.setState({ key: null, information: this.defaultContent })
  }

  render () {
    return (
      <div style={this.tableDivStyle}>
        <div style={this.borderDivStyle}>
          <div style={this.tableCellDivStyle}>{ReactHtmlParser(this.state.information)}</div>
        </div>
      </div>
    )
  }
}

export default GalleryInfoBox
