export default [
  {
    articleId: '1dee1f60-591c-11e9-a6c0-3feac4cfee5b',
    key: '',
    content: '<p>Die Konservierung und Restaurierung von Kunstwerken kann verschiedene Bedeutungsebenen berühren.</p>' +
      '<p>Der Erhalt eines Objektes vermag (Kultur-)geschichte, eine ehemals verwendete Technik oder ' +
      'ein altes Handwerk zu bewahren. So geben historische Objekte noch heute als Gegenstand verschiedener Forschungen ' +
      'Aufschluss über unsere Vergangenheit.</p>' +
      '<p>Auch der ästhetische Wert eines Kunstwerks als schöner Gegenstand kann Anlass zu einer Konservierung bzw. ' +
      'Restaurierung geben.</p>' +
      '<p>Und manchmal ist es vielleicht sogar möglich, eine persönliche, mit dem Kunstwerk zusammenhängende ' +
      'Erinnerung ein Stückchen länger zu bewahren.</p>'
  },
  {
    articleId: '2a16ae4c-591c-11e9-85c8-47e36577234d',
    key: 'leistungen',
    content: '<p>Die sorgfältige Konservierung und Restaurierung unterschiedlicher Objekte aus privatem, öffentlichem ' +
      'oder kirchlichem Besitz wird durch die Erstellung individueller Konzepte ermöglicht.</p>' +
      '<div style="margin-bottom: .7vh">Die Konservierung hat den Erhalt des Ist-Zustands eines Objektes zum Ziel. Sie beschränkt sich auf Maßnahmen, ' +
      'die natürliche Alterungsprozesse so lange als möglich hinauszögern und den Fortgang bereits entstandener ' +
      'Schadensphänomene stoppen.</div>' +
      '<div style="margin-bottom: .7vh">Die Restaurierung sieht darüber hinaus auch eine ästhetische Optimierung des Objektes, zum Beispiel die ' +
      'Retusche eines bereits konservierten Schadens, vor.</div>' +
      '<div>Um die natürliche Alterung zu ' +
      'entschleunigen, bzw. der Entstehung eines Schadens vorzubeugen, widmet sich die Präventive  Konservierung ' +
      'bereits im Vorfeld der Schaffung möglichst idealer Rahmenbedingungen für die Präsentation und Aufbewahrung von ' +
      'Kunstwerken.</div>' +
      '<h4 style="margin-top: 4vh; margin-bottom: 1vh">Die Leistungen im Überblick</h4>' +
      '<div>Konservierung und Restaurierung von Gemälden, Skulpturen und Objekten der Moderne<br />' +
      '<ul style="margin-top: .8vh">' +
      '<li>Bestands- und Zustandserfassung</li>' +
      '<li>Konzeptentwicklung und Erstellung von Kostenvoranschlägen</li>' +
      '<li>Ausführung von Konservierungs- und Restaurierungsmaßnahmen</li>' +
      '<li>Fotografische und schriftliche Dokumentation durchgeführter Maßnahmen</li>' +
      '</ul></div>' +
      '<div>Präventive Konservierung' +
      '<ul style="margin-top: .8vh">' +
      '<li>Standortbewertung und -optimierung</li>' +
      '<li>Klimamessungen, Pflege und Auswertung der Daten</li>' +
      '<li>Sammlungsbetreuung, Wartungen in regelmäßigen Zeitabständen</li>' +
      '<li>Betreuung von Objekten während Ausstellungsaufbauten und -abbauten</li>' +
      '<li>Betreuung von Ausstattungs-/Sammlungsauslagerungen</li>' +
      '<li>Kurierfahrten</li>' +
      '<li>Anfertigung von Staub- und Schwingschutzen für Gemälderückseiten</li>' +
      '<li>Beratung zu und ggf. Anfertigung von individuellen Aufhängungen oder Diebstahlschutzvorrichtungen</li>' +
      '</ul></div>' +
      '<div>Untersuchungen zur Klärung der Werktechnik, Werkgeschichte sowie des Objektzustandes' +
      '<ul style="margin-top: .8vh">' +
      '<li>Mikroskopische Untersuchungen</li>' +
      '<li>Stratigrafische Untersuchungen</li>' +
      '<li>Anfertigung von Querschliffen</li>' +
      '<li>Strahlendiagnostische Verfahren (UV-Fluoreszenz, Infrarotstrahlung, Röntgen)</li>' +
      '</ul></div>'
  },
  {
    articleId: '4d395542-643d-11e9-a72d-83611c9f01e9',
    key: 'profil',
    content: '<p><b>Freiberufliche Tätigkeit</b><br />Start in die Selbstständigkeit 2009 mit eigener Werkstatt seit August 2014</p>' +
      '<p><b>Mitarbeit</b><br />Seit November 2007<br />Ingrid Bussenius, Objektpräsentation und Ausstellungsgestaltung, Köln' +
      '<br /><a href="https://ingrid-bussenius.de" target="_blank">www.ingrid-bussenius.de</a></p>' +
      '<p>August 2010 - März 2016<br />Prof. Dr. Regina Urbanek, Restaurierungsatelier, Brühl</p>' +
      '<p><b>Studium</b><br />CICS Cologne Institute for Conservation Science TH Köln<br />Fachbereich ' +
      '„Gemälde, Skulptur und Objekte der Moderne“</p>' +
      '<div style="margin-bottom: 1vh">Master of Arts &nbsp;&nbsp;&ndash;&nbsp;&nbsp; Februar 2011 - Februar 2013<br />„Konservatorische Konzepte für ein Schaudepot in der ' +
      'romanischen Pfarrkirche St. Maria Lyskirchen in Köln“</div>' +
      '<div style="margin-bottom: 0.7vh">Bachelor of Arts &nbsp;&nbsp;&ndash;&nbsp;&nbsp; September 2007-Ende August 2010<br />„Untersuchung und Restaurierung eines Kruzifixes ' +
      'des 14. Jahrhunderts (Museum Kolumba)“</div>' +
      '</p><div style="margin-bottom: 0.7vh">Diverse Praktika während des Studiums - unter anderem:<br />' +
      '„International Conservation Workshop“ auf Lopud, Kroatien<br />' +
      'LVR-Amt für Denkmalpflege im Rheinland\n</div>' +
      '<p><b>Zwei Jahre Vorpraktikum</b><br />September 2006 - Ende August 2007<br />LVR-Amt für Denkmalpflege im ' +
      'Rheinland, Brauweiler<br />Dipl.-Rest. Marc Peez, Köln</p>' +
      '<p>September 2005 - September 2006<br />Restaurierungsatelier Barbara Friedrich, Dresden<br />Restauratorin im ' +
      'VDR und Dipl.-Gestalterin</p>' +
      '<div><b>Veröffentlichungen</b></div>' +
      '<div style="margin-bottom: 0.7vh">„Sieben Gemälde, sieben Konzepte. Zur Konservierung und Restaurierung ausgewählter ' +
      'Gemälde der Fürstenberg Stiftung Eggeringhausen“</div>' +
      '<div>in „Kleine Freuden. Gemälde der Fürstenberg Stiftung Eggeringhausen zu Besuch auf Schloss Neuhaus.“ Hrsg. ' +
      'Andreas Neuwöhner im Auftrag der Stadt Paderborn. Autorin Silke Köhn. Paderborn 2018</div><br />' +
      '<div style="margin-bottom: 0.7vh">In Zusammenarbeit mit Ingrid Bussenius:<br />„Zur Gestaltung der Präsentationen von Schatzkunst in Kirchen und Museen“ ' +
      'im Band „Schatzkunst in rheinischen Kirchen und Museen“. Verein für Christliche Kunst im Erzbistum Köln und ' +
      'Aachen,<br />Verlag Schnell &amp; Steiner GmbH, Regensburg 2013</div>' +
      '<div style="margin-bottom: 0.7vh">und</div>' +
      '<div>in „Colonia Romanica. Jahrbuch des Fördervereins Romanische Kirchen Köln e.V. 2015. Schatzkunstund Schatzorte ' +
      'in den romanischen Kirchen Kölns.” Greven Verlag Köln GmbH, Köln 2015' +
      '<p><b>Vortrag</b><br/>„Konservatorische Konzepte für ein Schaudepot in der romanischen Kirche St. Maria ' +
      'Lyskirchen Köln“</p>' +
      '<p>Mastertage des CICS 2013 im Rahmen der Exponatec, Messe Köln</p>',
    sliderThumbnails: ['profil.jpg']
  },
  {
    articleId: 'f72624fe-6442-11e9-9d30-13ac42e21be5',
    key: 'kontakt',
    content: '<div style="margin-bottom: .7vh">Sie erreichen die Werkstatt mit öffentlichen Verkehrsmitteln oder dem Auto.<br />Bitte vereinbaren Sie ' +
      'im Vorfeld einen Termin:<br /><br />' +
      '<a style="color: #545253" href="mailto:mail@verena-ebel.de"><b>mail@verena-ebel.de</b></a> oder ' +
      '<b>0176 64 09 63 44</b></div>' +
      '<div>Verena Ebel, Restauratorin M.A.<br />Vor den Siebenburgen 34<br />50676 Köln</div><br />' +
      '<p><b>Öffentliche Verkehrsmittel</b><br />Haltestelle „Eifelstraße“ (Linie 12, 15, 16) oder<br />' +
      '„Ulrepforte“ (Linie 15, 16), 5-10 Minuten Fußweg</p>' +
      '<p><b>Auto</b><br />Bitte beachten Sie, dass die Werkstatt in einer Einbahnstraße liegt.</p>' +
      '<p><a href="https://goo.gl/maps/kc5c1vWETuYeDhRZ6" target="_blank">Klicken Sie hier, um die Navigation über Google Maps zu starten.</a></p>'
  },
  {
    articleId: '9ca9d344-6443-11e9-bec3-bb06b86e0834',
    key: 'anfrage',
    content: '<p>Hier entsteht ein Formular zur vereinfachten Anfrage über eine Konservierung oder Restaurierung.</p>' +
      '<p>Bis dahin können Sie gerne unter<br />' +
      '<a href="mailto:mail@verena-ebel.de">mail@verena-ebel.de</a> per Mail oder <span style="color: #999999">0176 64 09 63 44</span> ' +
      'telefonisch<br />Kontakt aufnehmen, um mehr über eine Konservierung oder Restaurierung zu erfahren.'
  },
  {
    articleId: '62c5e22a-82da-11e9-9289-2bff68b28128',
    key: 'referenzen',
    content: '<p style="margin-bottom: 2vh; font-weight: bold">Referenzen</p>' +
      '<div>Ein Auszug bisheriger Auftraggeber und Kooperationen:</div>' +
      '<ul>' +
      '<li>Förderverein f. d. Marienkapelle auf dem Karmelenberg, Bassenheim</li>' +
      '<li>Fürstenberg-Stiftung Eggeringhausen</li>' +
      '<li>Galerie Thomas Zander, Köln</li>' +
      '<li>Generalvikariat Erzbistum Köln</li>' +
      '<li>Ingrid Bussenius Objektpräsentation und Ausstellungsgestaltung, Köln</li>' +
      '<li>Kai 10 | Arthena Foundation, Düsseldorf</li>' +
      '<li>Kolumba Kunstmuseum des Erzbistums Köln</li>' +
      '<li>LVR-Amt für Denkmalpflege im Rheinland</li>' +
      '<li>„Museum für kirchliches Kulturerbe“ Šv. Mykolo, Vilnius</li>' +
      '<li>Museum Schloss Homburg</li>' +
      '<li>Museum Schnütgen Köln</li>' +
      '<li>Restaurierungsprogramm Bildende Kunst des Landes NRW</li>' +
      '<li>St. Andreas, Düsseldorf</li>' +
      '<li>St. Servatius, Siegburg</li>' +
      '<li>verschiedene weitere Kirchengemeinden in Köln und Umgebung</li>' +
      '</ul>'
  },
  {
    articleId: '264afcbe-591c-11e9-8bba-33e1a274f364',
    key: 'impressum',
    content: '<p><b>Verena Ebel</b><br />Vor den Siebenburgen 34<br />50676 Köln<br />mail@verena-ebel.de<br />0176 640 963 44</p>' +
      '<p>Das Copyright für alle nicht anderweitig gekennzeichneten Fotos sowie der Gestaltung der Webseite liegt bei ' +
      'Verena Ebel. Köln 2019.</p>' +
      '<p>Umsetzung der Webseite durch Timo Ebel. Lich 2019.</p>'
  },
  {
    articleId: '69b507d6-65ee-11e9-8737-1f9450ede6e8',
    key: 'datenschutz',
    content: `<p>
        <b>Verantwortliche Stelle im Sinne der Datenschutzgesetze,<br>
        insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</b>
    </p>
    <p>
        Verena Ebel
        <br>Vor den Siebenburgen 34
        <br>50676 Köln
        <br>
        <br>Telefon: +49 (0) 176 64 09 63 44
        <br>E-Mail: <a href="mailto:mail@verena-ebel.de">mail@verena-ebel.de</a>
    </p>
    <h4>Ihre Betroffenenrechte</h4>
    <p>
        Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte
        ausüben:
    </p>
    <ul>
        <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,</li>
        <li>Berichtigung unrichtiger personenbezogener Daten,</li>
        <li>Löschung Ihrer bei uns gespeicherten Daten,</li>
        <li>
            Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht
            löschen dürfen,
        </li>
        <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und</li>
        <li>
            Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns
            abgeschlossen haben.
        </li>
    </ul>
    <p>
        Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft
        widerrufen.
    </p>
    <p>
        Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde wenden. Ihre
        zuständige
        Aufsichtsbehörde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen
        Verletzung. Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter:
        <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="noopener">https://www.bfdi.bund.de/DE/Infothek/ Anschriften_Links/anschriften_links-node.html</a>.
    </p>
    <h4>Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte</h4>
    <p>
        Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser Datenschutzerklärung genannten Zwecken. Eine
        Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den genannten Zwecken findet nicht statt. Wir
        geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
    </p>
    <ul >
        <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,</li>
        <li>die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist,</li>
        <li>die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,</li>
    </ul>
    <p>
        die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur Annahme besteht, dass
        Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.
    </p>
    <h4>Löschung bzw. Sperrung der Daten</h4>
    <p>
        Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir speichern Ihre personenbezogenen
        Daten daher nur so lange, wie dies zur Erreichung der hier genannten Zwecke erforderlich ist oder wie es die vom
        Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen. Nach Fortfall des jeweiligen Zweckes bzw. Ablauf
        dieser Fristen werden die entsprechenden Daten routinemäßig und entsprechend den gesetzlichen Vorschriften
        gesperrt oder gelöscht.
    </p>
    <h4>Erfassung allgemeiner Informationen beim Besuch unserer Website</h4>
    <p>
        Wenn Sie auf unsere Website zugreifen, werden automatisch mittels eines Cookies Informationen allgemeiner Natur
        erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete
        Betriebssystem, den Domainnamen Ihres Internet-Service-Providers und ähnliches. Hierbei handelt es sich
        ausschließlich um Informationen, welche keine Rückschlüsse auf Ihre Person zulassen.
    </p>
    <p>
        Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von Webseiten korrekt
        auszuliefern und fallen bei Nutzung des Internets zwingend an. Sie werden insbesondere zu folgenden Zwecken
        verarbeitet:
    </p>
    <ul >
        <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
        <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
        <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
        <li>zu weiteren administrativen Zwecken.</li>
    </ul>
    <p>
        Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem berechtigten Interesse aus den vorgenannten
        Zwecken zur Datenerhebung. Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Empfänger
        der Daten sind nur die verantwortliche Stelle und ggf. Auftragsverarbeiter.
    </p>
    <p>
        Anonyme Informationen dieser Art werden von uns ggfs. statistisch ausgewertet, um unseren Internetauftritt und
        die dahinterstehende Technik zu optimieren.
    </p>
    <h4>SSL-Verschlüsselung</h4>
    <p>
        Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik
        entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
    </p>
    <h4>Kontaktformular</h4>
    <p>
        Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder Kontaktformular mit uns in Kontakt, erteilen Sie uns zum
        Zwecke der Kontaktaufnahme Ihre freiwillige Einwilligung. Hierfür ist die Angabe einer validen E-Mail-Adresse
        erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe
        weiterer Daten ist optional. Die von Ihnen gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie
        für mögliche Anschlussfragen gespeichert. Nach Erledigung der von Ihnen gestellten Anfrage werden
        personenbezogene Daten automatisch gelöscht.
    </p>
    <h4><strong>Änderung unserer Datenschutzbestimmungen</strong></h4>
    <p>
        Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen
        Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei
        der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
    </p>
    <p>
        <em>Die Datenschutzerklärung wurde mit dem </em><a href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/" target="_blank" rel="noopener"><em>Datenschutzerklärungs-Generator der activeMind AG erstellt</em></a><em>.</em>
    </p>`
  }
]
