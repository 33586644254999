import componentDS from '../../datasources/component'
import pathDS from '../../datasources/path'
import routeDS from '../../datasources/route'
import ImageCarousel from '../../Component/AdditionalInformationComponent/ImageCarousel'

class Route {
  constructor () {
    this.componentsById = {}
    this.pathsById = {}

    componentDS.map(async com => (this.componentsById[com.componentId] = com))
    pathDS.map(async path => (this.pathsById[path.pathId] = path))
  }

  async getRoutes () {
    return routeDS.map(route => {
      return {
        routeId: route.routeId,
        paths: route.pathIds.map(pathId => this.pathsById[pathId]).map(path => path.path),
        contentComponent: this._getContentComponent(route),
        additionalInformationComponent: this._getAdditionalInformationComponent(route),
        parameters: this.componentsById[route.componentId].parameters,
        exact: route.exact
      }
    })
  }

  _getContentComponent (route) {
    return this._getComponent('ContentComponent', route.componentId)
  }

  _getAdditionalInformationComponent (route) {
    return this.componentsById[route.componentId].additionalInformationComponent
      ? this._getComponent('AdditionalInformationComponent', route.componentId)
      : ImageCarousel
  }

  _getComponent (componentType, componentId) {
    const componentTypeProperty = componentType.charAt(0).toLowerCase() + componentType.slice(1)

    return require(`../../Component/${componentType}/${this.componentsById[componentId][componentTypeProperty]}`).default
  }
}

export default Route
