import React from 'react'
import * as PropTypes from 'prop-types'

import Lightboxes from './Gallery/Lightboxes'
import AbstractContent from './AbstractContent'
import PreviewThumbnail from './Gallery/PreviewThumbnail'
import PreviewPlaceholder from './Gallery/PreviewPlaceholder'

class Gallery extends AbstractContent {
  constructor (props) {
    super(props)

    this.divStyle = {
      paddingLeft: '2vh',
      paddingTop: '3vh',
      paddingBottom: '3vh solid #ffffff',
      height: '62vh',
      overflowY: 'hidden',
      textAlign: 'left'
    }

    this.rowDivStyle = {
      margin: 0,
      paddingBottom: '2vh'
    }

    this.emptyPreviewDivStyle = {
      display: 'table',
      height: '18.3vh',
      width: '22.4vh',
      background: '#999999',
      float: 'left',
      color: '#ffffff'
    }

    this.noProjectDivStyle = {
      background: '#545253'
    }

    this.innerEmptyPreviewDivStyle = {
      display: 'table-cell',
      verticalAlign: 'middle',
      textAlign: 'center'
    }

    const GalleryRepository = require(`../../Repository/${props.parameters.galleryRepository}`).default
    this.galleryRepository = new GalleryRepository()

    this.state = {
      thumbnails: 'Loading . . .',
      galleries: [],
      lightboxes: null
    }

    this.lightboxesRef = null
    this.thumbnailRefs = []
  }

  async componentDidMount () {
    const galleries = await this.galleryRepository.getGalleries()
    const lightboxes = <Lightboxes ref={ref => (this.lightboxesRef = ref)} galleries={galleries} />
    const thumbnails = this._buildRows(galleries)

    this.setState({ thumbnails, galleries, lightboxes })
  }

  _selectGallery (gallery) {
    this.additionalInformationComponent.setInformation(
      gallery.galleryId,
      this._buildAdditionalInformation(gallery)
    )

    this._unselectGalleries([gallery.galleryId])
  }

  _unselectGalleries (except = []) {
    for (const ref of this.thumbnailRefs) {
      if (except.length && except.includes(ref.galleryId)) continue

      ref.ref.unselect()
    }

    if (!except.length) this.additionalInformationComponent.clearInformation()
  }

  _buildAdditionalInformation (gallery) {
    if (!gallery) return ''

    return `<b>${gallery.title}</b><br /><br />${gallery.description}`
  }

  _openGallery (galleryId) {
    this.lightboxesRef.setLightboxState(galleryId, true)
    this._unselectGalleries()
  }

  _buildRows (galleries) {
    const rows = []
    for (let i = 0; i < 3; i++) {
      rows.push(<div style={this.rowDivStyle} key={`row-div-${i}`}>{this._buildCols(galleries, i)}</div>)
    }

    return rows
  }

  _buildCols (galleries, rowIndex) {
    const gals = [
      galleries[rowIndex * 3],
      galleries[(rowIndex * 3) + 1],
      galleries[(rowIndex * 3) + 2]
    ]

    const cols = []
    for (let position = 0; position < 3; position++) {
      const marginLeft = position === 1 ? '2vh' : '0vh'
      const marginRight = position === 1 ? '2vh' : '0vh'

      const noProjectDivStyle = {
        ...this.emptyPreviewDivStyle,
        ...this.noProjectDivStyle,
        marginLeft: marginLeft,
        marginRight: marginRight,
        cursor: 'default'
      }

      if (!gals[position]) {
        cols.push(<div style={noProjectDivStyle} key={`none-${position}`}>
          <div style={this.innerEmptyPreviewDivStyle}> </div>
        </div>)
        continue
      }

      const image = gals[position] && gals[position].thumbnail
        ? <PreviewThumbnail
          wrappedRef={ref => this.thumbnailRefs.push({ galleryId: gals[position].galleryId, ref: ref })}
          key={`div-col-${gals[position].galleryId}`}
          marginLeft={marginLeft}
          marginRight={marginRight}
          gallery={gals[position]}
          onFirstClick={() => this._selectGallery(gals[position])}
          onSecondClick={() => this._openGallery(gals[position].galleryId)}
          onClickOutside={() => this.additionalInformationComponent.clearInformation()}
        />
        : <PreviewPlaceholder
          wrappedRef={ref => this.thumbnailRefs.push({ galleryId: gals[position].galleryId, ref: ref })}
          key={`div-col-${gals[position].galleryId}`}
          marginLeft={marginLeft}
          marginRight={marginRight}
          gallery={gals[position]}
          onFirstClick={() => this._selectGallery(gals[position])}
          onSecondClick={() => this._openGallery(gals[position].galleryId)}
          onClickOutside={() => this.additionalInformationComponent.clearInformation()}
        />

      cols.push(image)
    }

    return cols
  }

  render () {
    return (
      <div id="gallery" key="gallery" style={this.divStyle}>
        {this.state.thumbnails}
        {this.state.lightboxes}
      </div>
    )
  }
}

Gallery.propTypes = {
  location: PropTypes.object.isRequired,
  parameters: PropTypes.object.isRequired
}

export default Gallery
