import React from 'react'
import AbstractContent from './AbstractContent'

class PageNotFound extends AbstractContent {
  render () {
    return (
      <div>Not found.</div>
    )
  }
}

export default PageNotFound
