import React, { Component } from 'react'
import redSquare from './redsquare.png'

class Street extends Component {
  constructor (props) {
    super(props)

    this.divStyle = {
      marginBottom: '1.5vh'
    }

    this.squareStyle = {
      width: '0.55vh',
      height: '0.55vh',
      paddingBottom: '.35vh'
    }
  }

  render () {
    return <div style={this.divStyle}>
      Vor den Siebenburgen 34 <img src={redSquare} style={this.squareStyle} alt={'red square'}/> 50676 Köln
    </div>
  }
}

export default Street
