import React from 'react'
import * as PropTypes from 'prop-types'

import Overlay from './Overlay'
import ClickablePreview from './ClickablePreview'
import enhanceWithClickOutside from 'react-click-outside'

class PreviewThumbnail extends ClickablePreview {
  constructor (props) {
    super(props)

    this.previewDivStyle = {
      display: 'inline-block',
      position: 'relative'
    }

    this.previewImageStyle = {
      cursor: 'pointer',
      height: '18.3vh',
      width: '22.4vh'
    }
  }

  handleClickOutside () {
    this._onClickOutside(this.props.onClickOutside)
  }

  _buildThumbnail (gallery) {
    const imageStyle = {
      ...this.previewImageStyle,
      marginLeft: this.props.marginLeft,
      marginRight: this.props.marginRight
    }

    return <img
      style={imageStyle}
      key={`img-col-${gallery.galleryId}`}
      alt="carousel1"
      src={require(`../../../datasources/gallery/thumbnails/${gallery.thumbnail}`)}
    />
  }

  render () {
    return <div style={this.previewDivStyle} onClick={() => this._onClick(this.props.onFirstClick)}>
      {this._buildThumbnail(this.props.gallery)}
      {this.state.active ? <Overlay
        marginLeft={this.props.marginLeft}
        marginRight={this.props.marginRight}
        onClick={this.props.onSecondClick}
      /> : ''}
    </div>
  }
}

PreviewThumbnail.propTypes = {
  marginLeft: PropTypes.string.isRequired,
  marginRight: PropTypes.string.isRequired,
  gallery: PropTypes.object.isRequired,
  onFirstClick: PropTypes.func.isRequired,
  onSecondClick: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired
}

export default enhanceWithClickOutside(PreviewThumbnail)
