export default [
  {
    routeId: '10ee5f20-48b2-11e9-bbe1-b7436724cfcc',
    pathIds: [
      '3380bc96-48a2-11e9-be67-7bf535c789e4',
      '3f20f91c-48a2-11e9-8e78-3b7da36e777b',
      '4979dea6-48a2-11e9-9368-431be68af38f',
      '0658cce6-49cd-11e9-9116-d35470e0d803',
      '2409e2fa-6441-11e9-8d3a-37bcc4b15d1b',
      '0b25e96c-6443-11e9-96b4-e75a9260bd4c',
      'b0b693f4-6443-11e9-87c0-d36e31161ceb',
      '7c69af4e-65ee-11e9-b474-efe4ab2a5b4a',
      '6fe52d3a-82da-11e9-9e81-6b9eb961e3d3'
    ],
    componentId: '058ea410-48a2-11e9-b561-b30487edfca2',
    exact: true
  },
  {
    routeId: 'def154ea-54ba-11e9-a892-aba3c99da91a',
    pathIds: ['9bcea0f0-54ba-11e9-8ea8-e38668200a3b'],
    componentId: 'c1ed5330-54ba-11e9-8393-674ffa30baa4',
    exact: true
  },
  {
    routeId: '1cbf517e-48b2-11e9-8bf8-37c8ce80ee95',
    pathIds: ['8054a614-48b0-11e9-a38e-3fe930dce87f'],
    componentId: '74dbcfa6-48b0-11e9-b2c2-0faebeae9521',
    exact: false
  }
]
