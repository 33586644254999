export default [
  {
    componentId: '058ea410-48a2-11e9-b561-b30487edfca2',
    contentComponent: 'Article',
    parameters: {
      articleRepository: 'StaticFile/Article'
    }
  },
  {
    componentId: 'c1ed5330-54ba-11e9-8393-674ffa30baa4',
    contentComponent: 'Gallery',
    additionalInformationComponent: 'GalleryInfoBox',
    parameters: {
      galleryRepository: 'StaticFile/Gallery'
    }
  },
  {
    componentId: '74dbcfa6-48b0-11e9-b2c2-0faebeae9521',
    contentComponent: 'PageNotFound'
  }
]
