import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import GalleryRepository from '../../Repository/StaticFile/Gallery'

class ImageCarousel extends Component {
  constructor (props) {
    super(props)

    // noinspection JSClosureCompilerSyntax
    this.galleryRepository = new GalleryRepository()

    this.state = {
      thumbnails: []
    }

    this.imgStyle = {
      width: '36.98vh',
      height: '30vh'
    }
  }

  async setInformation (thumbnails) {
    this.setState({ thumbnails, link: null })
  }

  async clearInformation () {
    await this.componentDidMount()
  }

  async componentDidMount () {
    this.setState({ thumbnails: await this.galleryRepository.getThumbnails(), link: '/galerie' })
  }

  componentWillUnmount () {
    this.setInformation = () => {}
    this.clearInformation = () => {}
  }

  render () {
    return (
      <Slider autoplay={true} autoplaySpeed={3500} speed={2000} arrows={false}>
        {this.state.thumbnails.map(thumbnail => {
          const image = <img alt={thumbnail.title} key={thumbnail.galleryId} src={require(`../../${thumbnail.path}`)} style={this.imgStyle} />
          return this.state.link
            ? <Link to={this.state.link} key={thumbnail.galleryId}>{image}</Link>
            : <div key={thumbnail.galleryId}>{image}</div>
        })}
      </Slider>
    )
  }
}

export default ImageCarousel
