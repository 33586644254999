import galleryDS from '../../datasources/gallery'

/**
 * @typedef ImageEntity {{path: string, description: string, sort: number}}
 * @typedef GalleryEntity {{galleryId: string, title: string, description: string, thumbnail: string, images: ImageEntity[]}}
 */

class Gallery {
  constructor () {
    this.galleriesById = {}
    for (const gal of galleryDS) this.galleriesById[gal.galleryId] = gal

    this.thumbnails = galleryDS
      .filter(gal => gal.thumbnail)
      .map(gal => ({
        galleryId: gal.galleryId,
        title: gal.title,
        path: `datasources/gallery/thumbnails/${gal.thumbnail}`
      }))
  }

  /**
   * @returns {Promise<GalleryEntity[]>}
   */
  async getGalleries () {
    return galleryDS
  }

  /**
   * @param galleryId
   * @returns {Promise<GalleryEntity>}
   */
  async getImages (galleryId) {
    return this.galleriesById[galleryId]
  }

  /**
   * @returns {Promise<{galleryId: string, path: string, title: string}[]>}
   */
  async getThumbnails () {
    return this.thumbnails
  }
}

export default Gallery
