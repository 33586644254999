import React, { Component } from 'react'
import Address from './Address'
import Logo from './Logo'

class Header extends Component {
  constructor (props) {
    super(props)

    this.divStyle = {
      width: '126.8vh',
      height: '14.5vh',
      marginTop: '3.5vh',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  }

  render () {
    return (
      <div id="header" style={this.divStyle}>
        <Address />
        <Logo />
      </div>
    )
  }
}

export default Header
