import articleDS from '../../datasources/article'

class Article {
  constructor () {
    this.articlesByKey = {}

    articleDS.forEach(article => {
      const newArticle = { ...article }
      if (newArticle.sliderThumbnails) newArticle.sliderThumbnails = article.sliderThumbnails.map(thumb => `datasources/article/${thumb}`)
      this.articlesByKey[article.key] = newArticle
    })
  }

  async getArticleByKey (key) {
    return this.articlesByKey[key]
  }
}

export default Article
