import { Component } from 'react'

/**
 * @abstract
 */
class AbstractContent extends Component {
  constructor (props) {
    super(props)

    this.additionalInformationComponent = { setInformation: () => {}, clearInformation: () => {} }
  }

  setAdditionalInformationComponent (component) {
    this.additionalInformationComponent = component
  }
}

export default AbstractContent
