import React from 'react'
import * as PropTypes from 'prop-types'
import enhanceWithClickOutside from 'react-click-outside'

import Overlay from './Overlay'
import ClickablePreview from './ClickablePreview'

class PreviewPlaceholder extends ClickablePreview {
  constructor (props) {
    super(props)

    this.previewDivStyle = {
      display: 'inline-block',
      position: 'relative'
    }

    this.emptyPreviewDivStyle = {
      display: 'table',
      height: '18.3vh',
      width: '22.4vh',
      background: '#999999',
      float: 'left',
      color: '#ffffff'
    }

    this.innerEmptyPreviewDivStyle = {
      display: 'table-cell',
      verticalAlign: 'middle',
      textAlign: 'center'
    }
  }

  _buildThumbnailPlaceHolder (gallery) {
    const divStyle = {
      ...this.emptyPreviewDivStyle,
      marginLeft: this.props.marginLeft,
      marginRight: this.props.marginRight,
      cursor: 'pointer'
    }

    return <div
      key={gallery.galleryId}
      style={divStyle}
    >
      <div style={this.innerEmptyPreviewDivStyle}>{gallery ? gallery.title || '' : ''}</div>
    </div>
  }

  render () {
    return <div style={this.previewDivStyle} onClick={() => this._onClick(this.props.onFirstClick)}>
      {this._buildThumbnailPlaceHolder(this.props.gallery)}
      {this.state.active ? <Overlay
        marginLeft={this.props.marginLeft}
        marginRight={this.props.marginRight}
        onClick={this.props.onSecondClick}
      /> : ''}
    </div>
  }
}

PreviewPlaceholder.propTypes = {
  marginLeft: PropTypes.string.isRequired,
  marginRight: PropTypes.string.isRequired,
  gallery: PropTypes.object.isRequired,
  onFirstClick: PropTypes.func.isRequired,
  onSecondClick: PropTypes.func.isRequired
}

export default enhanceWithClickOutside(PreviewPlaceholder)
