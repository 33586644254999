import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import * as PropTypes from 'prop-types'
import AbstractContent from './AbstractContent'
import ImageCarousel from '../AdditionalInformationComponent/ImageCarousel'

class Article extends AbstractContent {
  constructor (props) {
    super(props)

    this.divStyle = {
      height: '55vh',
      padding: '5vh',
      overflowY: 'auto',
      textAlign: 'left',
      verticalAlign: 'middle',
      lineHeight: '2.8vh'
    }

    this.articlesByKey = {}

    const ArticleRepository = require(`../../Repository/${props.parameters.articleRepository}`).default
    this.articleRepository = new ArticleRepository()

    this.state = {
      article: {
        content: 'Loading . . .'
      }
    }
  }

  async componentDidMount () {
    const key = this._extractKey(this.props.location.pathname)
    this.setState({ article: await this._getArticleByKey(key) })
  }

  async componentDidUpdate () {
    this._updateSlider()

    const key = this._extractKey(this.props.location.pathname)

    if (this.state.article.key === key) return
    this.setState({ article: await this._getArticleByKey(key) })
  }

  _updateSlider () {
    const interval = setInterval(async () => {
      if (!(this.additionalInformationComponent instanceof ImageCarousel)) return
      clearInterval(interval)

      if (!this.state.article.sliderThumbnails) {
        await this.additionalInformationComponent.clearInformation()
        return
      }

      await this.additionalInformationComponent.setInformation(this.state.article.sliderThumbnails.map(thumb => ({
        galleryId: 'profil',
        path: thumb
      })))
    }, 10)
  }

  _extractKey (path) {
    return (path || '/').slice(1)
  }

  async _getArticleByKey (key) {
    const articleExists = !!this.articlesByKey[key]
    const outOfDate = !!this.articlesByKey[key] && Date.now() - this.articlesByKey[key].timestamp > 5000

    if (!articleExists || outOfDate) {
      this.articlesByKey[key] = (await this.articleRepository.getArticleByKey(key)) || { key, content: '' }
      this.articlesByKey[key].timestamp = Date.now()
    }

    return this.articlesByKey[key]
  }

  render () {
    return (
      <div id="article" key="article" style={this.divStyle}>{ReactHtmlParser(this.state.article.content)}</div>
    )
  }
}

Article.propTypes = {
  location: PropTypes.object.isRequired,
  parameters: PropTypes.object.isRequired
}

export default Article
