import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Radium from 'radium'
import './App.css'
import RouteRepository from '../Repository/StaticFile/Route'

import Header from './Layout/Header'
import Separator from './Layout/Separator'

import Footer from './Layout/Footer'

import Menu from './Layout/Menu'
import Content from './Layout/Content'
import AdditionalInformation from './Layout/AdditionalInformation'

class App extends Component {
  constructor (props) {
    super(props)

    this.mainDivStyle = {
      width: '116.8vh',
      height: '69.5vh',
      paddingTop: '5vh',
      marginLeft: 'auto',
      marginRight: 'auto'
      // border: '1px dashed black'
    }

    this.routeRepository = new RouteRepository()
    this.currentContentComponent = null
    this.currentAdditionalInformationComponent = null

    this.state = {
      routes: []
    }
  }

  async componentDidMount () {
    this.setState({
      routes: await this.routeRepository.getRoutes(),
      loggedIn: !parseInt(process.env.REACT_APP_LOGIN_ENABLED)
    })
  }

  _passChanged (event) {
    this.setState({
      loggedIn: event.target.value === 'kablam!'
    })
  }

  _buildSwitch (ComponentClass) {
    return (
      <Switch>
        {this.state.routes.map(route => this._buildRoute(ComponentClass, route))}
      </Switch>
    )
  }

  _buildRoute (ComponentClass, route) {
    return <Route
      exact={route.exact}
      path={route.paths}
      render={props => (this._buildComponent(ComponentClass, route, props))}
      key={route.routeId}
    />
  }

  _buildComponent (ComponentClass, route, props) {
    return <ComponentClass {...props }
      parameters={route.parameters}
      contentComponent={route.contentComponent}
      additionalInformationComponent={route.additionalInformationComponent}
      ref={async ref => {
        let shouldDistribute = false
        if (ref instanceof Content) {
          this.currentContentComponent = ref.getContentComponent()
          shouldDistribute = true
        }

        if (ref instanceof AdditionalInformation) {
          this.currentAdditionalInformationComponent = ref.getAdditionalInformationComponent()
          shouldDistribute = true
        }

        if (shouldDistribute) await this._distributeRefs()
      }}
    />
  }

  async _distributeRefs () {
    return new Promise(resolve => {
      let counter = 0

      const interval = setInterval(() => {
        if (this.currentContentComponent !== null && this.currentAdditionalInformationComponent !== null) {
          this.currentContentComponent.setAdditionalInformationComponent(this.currentAdditionalInformationComponent)

          clearInterval(interval)
          return resolve()
        }

        counter++
        if (counter > 5) {
          clearInterval(interval)
          resolve()
        }
      }, 100)
    })
  }

  render () {
    if (!this.state.loggedIn) {
      return (<div style={{ display: 'table', width: '100%' }}><div style={{ display: 'table-cell', width: '100%', height: '100vh', textAlign: 'center', verticalAlign: 'middle' }}>
        Password<br />
        <br />
        <input type="password" name="pass" onChange={e => this._passChanged(e)} />
      </div></div>)
    }

    return (
      <div id="container" style={{ fontSize: '1.8vh' }}>
        <Header />
        <Separator />
        <div id="main" style={this.mainDivStyle}>
          {this._buildSwitch(Menu)}
          {this._buildSwitch(Content)}
          {this._buildSwitch(AdditionalInformation)}
        </div>
        <Separator marginBottom=".5vh" />
        <Footer />
      </div>
    )
  }
}

export default Radium(App)
